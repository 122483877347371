import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="notification"
export default class extends Controller {
  static values = {
    duration: Number,
  };

  connect() {
    setTimeout(() => {
      this.element.remove();
      // @ts-ignore
    }, this.durationValue);
  }
}
